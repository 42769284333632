import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { StaticImage } from 'gatsby-plugin-image'

import Carousel from 'components/Carousel'

import {
  Container,
  PicturesContainder,
  ItemContainer,
  PictureContainer,
  PicturesCarousel,
} from './style'

const EventsPictures = () => (
  <Container>
    <PicturesContainder>
      <PicturesCarousel>
        <Carousel
          spaceBetweenCards={15}
          slidesPerView="auto"
          loop
          nextButtonClass="news-events-pictures"
          prevButtonClass="news-events-pictures"
        >
          <SwiperSlide key={1}>
            <ItemContainer>
              <p>1998</p>
              <PictureContainer>
                <StaticImage
                  src="../../../assets/images/fotos-eventos1.png"
                  alt=""
                  placeholder="blurred"
                  className="picture"
                />
              </PictureContainer>
            </ItemContainer>
          </SwiperSlide>
          <SwiperSlide key={2}>
            <ItemContainer>
              <p>1999</p>
              <PictureContainer>
                <StaticImage
                  src="../../../assets/images/fotos-eventos2.png"
                  alt=""
                  placeholder="blurred"
                  className="picture"
                />
              </PictureContainer>
            </ItemContainer>
          </SwiperSlide>
          <SwiperSlide key={3}>
            <ItemContainer>
              <p>2000</p>
              <PictureContainer>
                <StaticImage
                  src="../../../assets/images/fotos-eventos3.png"
                  alt=""
                  placeholder="blurred"
                  className="picture"
                />
              </PictureContainer>
            </ItemContainer>
          </SwiperSlide>
          <SwiperSlide key={4}>
            <ItemContainer>
              <p>2001</p>
              <PictureContainer>
                <StaticImage
                  src="../../../assets/images/fotos-eventos4.png"
                  alt=""
                  placeholder="blurred"
                  className="picture"
                />
              </PictureContainer>
            </ItemContainer>
          </SwiperSlide>
          <SwiperSlide key={5}>
            <ItemContainer>
              <p>2002</p>
              <PictureContainer>
                <StaticImage
                  src="../../../assets/images/fotos-eventos5.png"
                  alt=""
                  placeholder="blurred"
                  className="picture"
                />
              </PictureContainer>
            </ItemContainer>
          </SwiperSlide>
          <SwiperSlide key={6}>
            <ItemContainer>
              <p>2003</p>
              <PictureContainer>
                <StaticImage
                  src="../../../assets/images/fotos-eventos6.png"
                  alt=""
                  placeholder="blurred"
                  className="picture"
                />
              </PictureContainer>
            </ItemContainer>
          </SwiperSlide>
          <SwiperSlide key={7}>
            <ItemContainer>
              <p>2004</p>
              <PictureContainer>
                <StaticImage
                  src="../../../assets/images/fotos-eventos7.png"
                  alt=""
                  placeholder="blurred"
                  className="picture"
                />
              </PictureContainer>
            </ItemContainer>
          </SwiperSlide>
          <SwiperSlide key={8}>
            <ItemContainer>
              <p>2005</p>
              <PictureContainer>
                <StaticImage
                  src="../../../assets/images/fotos-eventos8.png"
                  alt=""
                  placeholder="blurred"
                  className="picture"
                />
              </PictureContainer>
            </ItemContainer>
          </SwiperSlide>
          <SwiperSlide key={9}>
            <ItemContainer>
              <p>2006</p>
              <PictureContainer>
                <StaticImage
                  src="../../../assets/images/fotos-eventos9.png"
                  alt=""
                  placeholder="blurred"
                  className="picture"
                />
              </PictureContainer>
            </ItemContainer>
          </SwiperSlide>
        </Carousel>
      </PicturesCarousel>
    </PicturesContainder>
  </Container>
)

export default EventsPictures
