import styled from 'styled-components'
import Amoeba1 from 'assets/vectors/news-header-vector1.svg'

export const Container = styled.div`
  width: 100%;
  height: 568px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.tertiaryDarkest};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 320px;
    }
  `}
`

export const TextContainer = styled.div`
  max-width: 430px;
  margin-right: 100px;
  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      margin-right: 0px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      padding-left: 28px;
    }
  `}
`

export const Title = styled.h1`
  margin-bottom: 40px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      margin-bottom: 30px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      position: relative;
      ${theme.fonts.heading3};
      max-width: 200px;
      z-index: 2;
    }
  `}
`

export const Description = styled.p`
  ${({ theme }) => theme.fonts.mediumText};
  color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextMedium};
    }
  `}
`

export const ImageContainer = styled.div`
  position: relative;
  height: 319px;
  width: 600px;

  ${({ theme }) => `
    @media (min-width:${theme.breakPoints.smallTablet}) and (max-width: ${theme.breakPoints.tablet}) {
      width: 400px;
      transform: translateY(-135px);
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 200px;
      height: 100px;
      transform: translate(-135px, -64px);
    }
    @media (max-width: ${theme.breakPoints.mediumMobile}) {
      transform: translate(-118px, -80px);
    }
    @media (max-width: ${theme.breakPoints.smallMobile}) {
      transform: translate(-96px, -80px);
    }
    @media (max-width: ${theme.breakPoints.smallestMobile}) {
      transform: translate(-80px, -80px);
    }
  `}
`

export const Amoeba = styled(Amoeba1)`
  position: absolute;
  top: 0px;

  ${({ theme }) => `
    @media (min-width:${theme.breakPoints.smallTablet}) and (max-width: ${theme.breakPoints.tablet}) {
      width: 100%;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 200px;
      height: 100px;
    }
  `}
`
