import React from 'react'

import {
  Container,
  TextContainer,
  Title,
  Description,
  ImageContainer,
  Amoeba,
} from './style'

const Header = () => (
  <Container>
    <TextContainer>
      <Title>Eventos e Notícias</Title>
      <Description>
        Queremos compartilhar com você nossos eventos, vídeos e informações mais
        recentes sobre a Síndrome de Moebius.
      </Description>
    </TextContainer>
    <ImageContainer>
      <Amoeba />
    </ImageContainer>
  </Container>
)

export default Header
