import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundMedium};
  padding-bottom: 24px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      min-height: 700px;
      padding-left: 28px;
      align-items: flex-start;
    }
  `}
`

export const Title = styled.h3`
  width: 1200px;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.primaryMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 900px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 300px;
    }
  `}
`

export const Subtitle = styled.p`
  width: 1200px;
  margin-bottom: 40px;
  ${({ theme }) => theme.fonts.mediumText};
  color: ${({ theme }) => theme.colors.neutralDarkest};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 900px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      ${theme.fonts.smallTextSemiBold};
      margin-bottom: 30px;

    }
  `}
`

export const CardsContainer = styled.div`
  position: relative;
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  justify-content: space-between;

  .swiper-slide {
    width: unset;
  }

  .swiper-button-next-news-news {
    position: absolute;
    bottom: -70px;
    right: 30%;
    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.mediumMobile}) {
        right: 28%;
      }
      @media (max-width: ${theme.breakPoints.smallestMobile}) {
        right: 24%;
      }
    `}
  }

  .swiper-button-prev-news-news {
    position: absolute;
    bottom: -70px;
    left: 30%;
    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.mediumMobile}) {
        left: 28%;
      }
      @media (max-width: ${theme.breakPoints.smallestMobile}) {
        left: 24%;
      }
    `}
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 900px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
    }
  `}
`
