import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { useMediaQuery } from 'react-responsive'

import useNewsAndEvents from 'graphql/useNewsAndEvents'

import {
  isPastDate,
  formatUpcommingEventsDate,
  formatPastEventsDate,
} from 'utils/date'

import theme from 'styles/theme'

import Seo from 'components/Seo'
import Layout from 'components/Layout'
import Header from 'components/News/Header'
import NextEvents from 'components/News/NextEvents'
import PastEvents from 'components/News/PastEvents'
import EventsPictures from 'components/News/EventsPictures'
import News from 'components/News/News'
import Videos from 'components/Videos'
import EventModal from 'components/News/EventModal'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`

const NewsPage = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakPoints.biggestMobile})`,
  })

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const data = useNewsAndEvents()
  const events = data.events.nodes
  const newsData = data.news.nodes
  const videosData = data.videos.nodes

  const pastEvents = []
  const upcommingEvents = []
  const news = []

  events.forEach((eventItem) => {
    if (eventItem.id !== '5c390358-af23-57a2-843e-78e521799d77')
      if (isPastDate(eventItem.rawDate)) {
        pastEvents.push({
          ...eventItem,
          formattedDate: formatPastEventsDate(eventItem.rawDate),
        })
      } else {
        upcommingEvents.push({
          ...eventItem,
          formattedDate: formatUpcommingEventsDate(eventItem.rawDate),
        })
      }
  })

  newsData.forEach((newsItem) =>
    news.push({
      ...newsItem,
      formattedDate: formatPastEventsDate(newsItem.rawDate),
    }),
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({})

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <Layout
          css={{
            backgroundColor: theme.colors.tertiaryDarkest,
            position: 'relative',
          }}
        >
          <Seo
            title="Eventos e Notícias"
            description="Queremos compartilhar com você nossos eventos, vídeos e informações mais recentes sobre a Síndrome de Moebius."
          />
          <Header />
          {upcommingEvents.length > 0 && <NextEvents data={upcommingEvents} />}
          <EventModal
            isModalOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            data={modalContent}
          />
          <PastEvents
            data={pastEvents}
            isMobile={isMobile}
            setIsModalOpen={setIsModalOpen}
            scrollToTop={scrollToTop}
            setModalContent={setModalContent}
          />
          <EventsPictures />
          <News isMobile={isMobile} data={news} />
          <Videos title="Vídeos" videos={videosData} isMobile={isMobile} />
        </Layout>
      </Wrapper>
    </ThemeProvider>
  )
}
export default NewsPage
