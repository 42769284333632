import React from 'react'
import { SwiperSlide } from 'swiper/react'

import CardItem from 'components/News/CardItem'
import Carousel from 'components/Carousel'

import { Container, Title, Subtitle, PastEventsContainer } from './style'

const PastEvents = ({
  isMobile,
  data,
  setModalContent,
  setIsModalOpen,
  scrollToTop,
}) => (
  <Container>
    <Title>O que já aconteceu</Title>
    <Subtitle>Saiba mais sobre os eventos que realizamos</Subtitle>
    <PastEventsContainer>
      {typeof window !== `undefined` &&
        !isMobile &&
        data.map((item) => (
          <CardItem
            key={`evento-passado-${item.id}`}
            opensModal
            formattedDate={item.formattedDate}
            title={item.title}
            description={item.briefDescription?.description}
            type="Eventos"
            onClickButton={() => {
              setModalContent(item)
              setIsModalOpen(true)
              scrollToTop()
            }}
          />
        ))}
      {typeof window !== `undefined` && isMobile && (
        <Carousel
          spaceBetweenCards={20}
          primaryButton
          nextButtonClass="news-past-events"
          prevButtonClass="news-past-events"
        >
          {data.map((item) => (
            <SwiperSlide key={`evento-passado-${item.id}`}>
              <CardItem
                opensModal
                type="Eventos"
                formattedDate={item.formattedDate}
                title={item.title}
                description={item.briefDescription?.description}
                onClickButton={() => {
                  setModalContent(item)
                  setIsModalOpen(true)
                  scrollToTop()
                }}
              />
            </SwiperSlide>
          ))}
        </Carousel>
      )}
    </PastEventsContainer>
  </Container>
)

export default PastEvents
