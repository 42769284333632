import React from 'react'

import {
  Card,
  CardTop,
  CardBottom,
  EventDate,
  EventTitle,
  EventDescription,
  Link,
  ArrowIcon,
  ButtonContainer,
} from './style'

const CardItem = ({
  type,
  formattedDate,
  title,
  description,
  opensModal,
  onClickButton,
  url,
}) => (
  <Card>
    <CardTop>
      <EventDate>
        <span>{type}</span> <span>|</span> <span>{formattedDate}</span>
      </EventDate>
      <EventTitle>{title}</EventTitle>
      <EventDescription>{description}</EventDescription>
    </CardTop>
    <CardBottom>
      {opensModal ? (
        <ButtonContainer onClick={onClickButton}>
          Saiba Mais <ArrowIcon />
        </ButtonContainer>
      ) : (
        <Link href={url} target="_blank" rel="noreferrer">
          Saiba Mais <ArrowIcon />
        </Link>
      )}
    </CardBottom>
  </Card>
)

export default CardItem
