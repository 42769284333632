import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0px;
  background: ${({ theme }) => theme.colors.backgroundMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding-left: 28px;
      align-items: flex-start;
    }
  `}
`

export const Title = styled.h3`
  width: 1200px;
  margin-bottom: 40px;
  color: ${({ theme }) => theme.colors.primaryMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 900px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 300px;
    }
  `}
`

export const Subtitle = styled.p`
  width: 1200px;
  margin-bottom: 40px;
  ${({ theme }) => theme.fonts.mediumText};
  color: ${({ theme }) => theme.colors.neutralDarkest};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 900px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      ${theme.fonts.smallTextSemiBold};
      margin-bottom: 30px;

    }
  `}
`

export const PastEventsContainer = styled.div`
  position: relative;
  display: flex;
  width: 1200px;
  flex-wrap: wrap;
  justify-content: space-between;

  .swiper-slide {
    width: unset;
  }

  .swiper-button-next-news-past-events {
    position: absolute;
    bottom: -70px;
    right: 30%;
    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.mediumMobile}) {
        right: 28%;
      }
      @media (max-width: ${theme.breakPoints.smallestMobile}) {
        right: 24%;
      }
    `}
  }

  .swiper-button-prev-news-past-events {
    position: absolute;
    bottom: -70px;
    left: 30%;
    ${({ theme }) => `
      @media (max-width: ${theme.breakPoints.mediumMobile}) {
        left: 28%;
      }
      @media (max-width: ${theme.breakPoints.smallestMobile}) {
        left: 24%;
      }
    `}
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.tablet}) {
      width: 900px;
    }
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      width: 700px;
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
    }
  `}
`

export const PicturesContainder = styled.div`
  width: 100vw;
  height: 474px;
  margin-top: 100px;
  background: ${({ theme }) => theme.colors.quaternaryLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      transform: translateX(-28px);
    }
  `}
`

export const PicturesCarousel = styled.div`
  position: relative;
  display: flex;
  margin-top: 86px;

  .swiper-slide {
    width: unset;
  }

  .swiper-button-next-news-past-events {
    position: absolute;
    bottom: -80px;
    right: 45%;
  }

  .swiper-button-prev-news-past-events {
    position: absolute;
    bottom: -80px;
    left: 45%;
  }

  @media (max-width: 1450px) {
    .swiper-button-next-news-past-events {
      right: 43%;
    }

    .swiper-button-prev-news-past-events {
      left: 43%;
    }
  }

  @media (max-width: 1100px) {
    .swiper-button-next-news-past-events {
      right: 41%;
    }

    .swiper-button-prev-news-past-events {
      left: 41%;
    }
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.smallTablet}) {
      .swiper-button-next-news-past-events {
        right: 40%;
      }
    .swiper-button-prev-news-past-events {
        left: 40%;
      }
    }
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      .swiper-button-next-news-past-events {
        right: 30%;
      }
      .swiper-button-prev-news-past-events {
        left: 30%;
      }
    }
      @media (max-width: ${theme.breakPoints.mediumMobile}) {
        .swiper-button-prev-news-past-events {
          left: 28%;
        }
        .swiper-button-next-news-past-events {
          right: 28%;
        }
      }
      @media (max-width: ${theme.breakPoints.smallestMobile}) {
        .swiper-button-prev-news-past-events {
          left: 24%;
        }
        .swiper-button-next-news-past-events {
          right: 24%;
        }
      }
  `}
`

export const ItemContainer = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ hasZoom }) => hasZoom && `background:red;`}

  p {
    ${({ theme }) => theme.fonts.largeBold};
    color: ${({ theme }) => theme.colors.primaryMedium};
    margin-bottom: 16px;
  }
`

export const PictureContainer = styled.div`
  width: 174px;
  height: 174px;
  border-radius: 50%;
  overflow: hidden;
`
