import styled from 'styled-components'
import Arrow from 'assets/vectors/arrow-head.svg'

export const Card = styled.div`
  width: 580px;
  height: 360px;
  display: flex;
  flex-direction: column;
  padding: 40px 32px 32px;
  justify-content: space-between;
  margin-bottom: 32px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.backgroundLight};
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.1);

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 251px;
      height: 310px;
      padding: 33px 16px 32px;
    }
  `}
`

export const CardTop = styled.div`
  display: flex;
  flex-direction: column;
`

export const EventDate = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  span {
    ${({ theme }) => theme.fonts.buttomLink};
    color: ${({ theme }) => theme.colors.neutralMedium};
    text-transform: uppercase;
    margin-right: 5px;
  }
`

export const EventTitle = styled.p`
  ${({ theme }) => theme.fonts.heading4};
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.tertiaryMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.mediumTextBlack}
      margin-bottom: 16px;
    }
  `}
`

export const EventDescription = styled.p`
  ${({ theme }) => theme.fonts.mediumText};
  color: ${({ theme }) => theme.colors.neutralMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.smallTextSemiBold}
    }
  `}
`

export const CardBottom = styled.div`
  width: 100%;
  display: flex;
`

export const ArrowIcon = styled(Arrow)`
  margin-left: 10px;
`

export const Link = styled.a`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.fonts.buttomLink};
  color: ${({ theme }) => theme.colors.primaryDarkest};
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.fonts.buttomLink};
  color: ${({ theme }) => theme.colors.primaryDarkest};
  cursor: pointer;
`
