import React from 'react'
import { SwiperSlide } from 'swiper/react'

import CardItem from 'components/News/CardItem'
import Carousel from 'components/Carousel'

import { Container, Title, Subtitle, CardsContainer } from './style'

const News = ({ isMobile, data }) => (
  <Container>
    <Title>Notícias</Title>
    <Subtitle>As informações sobre a síndrome e Moebitas </Subtitle>
    <CardsContainer>
      {typeof window !== `undefined` &&
        !isMobile &&
        data.map((item) => (
          <CardItem
            key={item.id}
            type="Notícias"
            formattedDate={item.formattedDate}
            title={item.newsTitle}
            description={item.briefDescription}
            url={item.url}
          />
        ))}
      {typeof window !== `undefined` && isMobile && (
        <Carousel
          spaceBetweenCards={20}
          primaryButton
          nextButtonClass="news-news"
          prevButtonClass="news-news"
        >
          {data.map((item) => (
            <SwiperSlide key={item.id}>
              <CardItem
                type="Notícias"
                formattedDate={item.formattedDate}
                title={item.newsTitle}
                description={item.briefDescription}
                url={item.url}
              />
            </SwiperSlide>
          ))}
        </Carousel>
      )}
    </CardsContainer>
  </Container>
)

export default News
