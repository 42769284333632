import styled from 'styled-components'
import Vector1 from 'assets/vectors/news-next-events-vector1.svg'
import Vector2 from 'assets/vectors/news-next-events-vector2.svg'
import VectorMobile from 'assets/vectors/available-treatments-header-vector1.svg'

export const Container = styled.div`
  width: 100%;
  min-height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0px;
  background: ${({ theme }) => theme.colors.backgroundMedium};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      padding: 50px 0px;
      min-height: 400px;
    }
  `}
`

export const Title = styled.h2`
  width: 616px;
  text-align: center;
  color: ${({ theme }) => theme.colors.quaternaryDarkest};
  margin-bottom: 40px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      ${theme.fonts.heading4};
      width: 260px;
      margin-bottom: 24px;
    }
  `}
`

export const CardsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      align-items: center;
    }
  `}
`

export const Card = styled.div`
  width: 728px;
  height: 221px;
  display: flex;
  padding: 22px;
  justify-content: center;
  margin-bottom: 40px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.primaryDarkest};
  z-index: 2;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 304px;
      height: 369px;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
      background: ${theme.colors.primaryMedium};

    }
  `}
`

export const Separator = styled.div`
  width: 3px;
  height: 100%;
  margin: 0px 32px;
  background: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      height: 3px;
      width: 100%;
      margin: 22px 0px;
    }
  `}
`

export const EventInfo = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: end;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 100%;
      align-items: center;
      text-align: center;
    }
  `}
`

export const EventDate = styled.p`
  ${({ theme }) => theme.fonts.buttomLink};
  color: ${({ theme }) => theme.colors.backgroundLight};
  text-transform: uppercase;
  margin-bottom: 13px;
`

export const EventTitle = styled.p`
  width: 250px;
  ${({ theme }) => theme.fonts.heading4};
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.quaternaryMedium};
`

export const EventLocation = styled.p`
  ${({ theme }) => theme.fonts.smallTextSemiBold};
  color: ${({ theme }) => theme.colors.backgroundLight};
  white-space: pre-line;
`

export const EventDescription = styled.div`
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    ${({ theme }) => theme.fonts.smallTextSemiBold};
    color: ${({ theme }) => theme.colors.backgroundLight};
  }

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      width: 220px;
      text-align: center;
    }
  `}
`

export const Amoeba1 = styled(Vector1)`
  position: absolute;
  top: 13px;
  left: -304px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`

export const Amoeba2 = styled(Vector2)`
  position: absolute;
  top: 13px;
  right: -350px;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: none;
    }
  `}
`

export const AmoebaMobile1 = styled(VectorMobile)`
  position: absolute;
  display: none;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: flex;
      top: 130px;
      left: -20px;
    }
  `}
`
export const AmoebaMobile2 = styled(VectorMobile)`
  position: absolute;
  display: none;

  ${({ theme }) => `
    @media (max-width: ${theme.breakPoints.biggestMobile}) {
      display: flex;
      top: 0px;
      right: -50px;
    }
  `}
`
