import React from 'react'

import {
  Container,
  Title,
  CardsContainer,
  Amoeba1,
  Amoeba2,
  Card,
  Separator,
  EventInfo,
  EventDescription,
  EventDate,
  EventTitle,
  EventLocation,
  AmoebaMobile1,
  AmoebaMobile2,
} from './style'

const NextEvents = ({ data }) => (
  <Container>
    <Title>Nossos próximos eventos</Title>
    <CardsContainer>
      <Amoeba1 />
      <Amoeba2 />
      <AmoebaMobile1 />
      <AmoebaMobile2 />
      {data.map((item) => (
        <Card key={item.id}>
          <EventInfo>
            <EventDate>{item.formattedDate}</EventDate>
            <EventTitle>{item.title}</EventTitle>
            <EventLocation>{item.address?.address}</EventLocation>
          </EventInfo>
          <Separator />
          <EventDescription>
            <p>{item.briefDescription?.description}</p>
          </EventDescription>
        </Card>
      ))}
    </CardsContainer>
  </Container>
)

export default NextEvents
